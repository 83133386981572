/** @format */

import http from '../js/http-client';

interface OrderType {
	companyName: string;
	createdTime: string;
	id: string;
	state: any;
	productName: string;
}

class OrderApi {
	/**
	 * 获取订单信息
	 * @param {any} id
	 * @returns {Promise}
	 */
	orderMsg(id: number | string) {
		return http.request<OrderType>({
			url: `/api/orders/order-by-id/${id}`,
			method: 'get',
		});
	}
	/**
	 * 获取订单信息
	 * @param {any} user_id
	 * @returns {Promise}
	 */
	orderList(user_id: number | string) {
		return http.request({
			url: `/api/orders/list/${user_id}`,
			method: 'get',
		});
	}
}

const orderApi = new OrderApi();

export default orderApi;
